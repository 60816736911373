import * as Sentry from '@sentry/browser';

import decode from 'jwt-decode';
import Keycloak, { KeycloakInitOptions } from 'keycloak-js';
import difference from 'lodash/difference';
import { socketAuthenticate } from 'microservices/feeder';
import { isFeatureAvailable } from 'services/features';
import { getStoreValue, store } from 'stores/store';
import { getServiceUrl, httpPost } from './api';
import { isTokenValid, logout, updateCoolbetAuthToken } from './auth';
import { logger } from './logger';

export const STORAGE_KEY_KEYCLOAK_TOKENS = 'coolbet_bo_keycloak';

const KEYCLOAK_CLIENT_ID = 'backoffice';
const KEYCLOAK_GRANT_TYPE = 'urn:ietf:params:oauth:grant-type:uma-ticket';

export const ROLES = {
    AUTOPAYOUT: {
        AUTHZSERVICENAME: 'autopayout',
        EDIT: 'autopayout:edit:autopayout',
        VIEW: 'autopayout:view:autopayout',
    },
    USERS: {
        AUTHZSERVICENAME: 'users',
        CREATE: 'users:create:users',
        VIEW: 'users:view:users',
        VIEW_MINIMAL: 'users:view-minimal:users',
        TERMS_AND_CONDITIONS_EDIT: 'terms-and-conditions:edit:users',
        TERMS_AND_CONDITIONS_VIEW: 'terms-and-conditions:view:users',
        USER_SETTINGS_EDIT: 'user-settings:edit:users',
        USER_SETTINGS_VIEW: 'user-settings:view:users',
        USER_COMMENTS_VIEW: 'comments:view:users',
        USER_LOGIN_HISTORY_VIEW: 'user-login-history:view:users',
        USERS_EDIT: 'users:edit:users',
        REOPEN_REQUEST_EDIT: 'reopen-request:edit:users',
        REOPEN_REQUEST_VIEW: 'reopen-request:view:users',
    },
    AML: {
        AUTHZSERVICENAME: 'aml',
        READ: 'aml:read:aml',
        WRITE: 'aml:write:aml',
        INCREASE_RISK_ASSESSMENT: 'risk-assessment:increase:aml',
        DECREASE_RISK_ASSESSMENT: 'risk-assessment:decrease:aml',
        REPORT_READ: 'report:read:aml',
    },
    AUTH_BO: {
        USER_MANAGE: 'user:manage:auth-bo',
    },
    CRM: {
        AUTHZSERVICENAME: 'crm',
        READ: 'crm:read:crm',
        WRITE: 'crm:write:crm',
    },
    DONBEST: {
        AUTHZSERVICENAME: 'donbest',
        READ: 'donbest:read:donbest',
        WRITE: 'donbest:write:donbest',
    },
    PAYMENTS2: {
        AUTHZSERVICENAME: 'payments2',
        DECLINE: 'payments2:decline:payments2',
        EXPORT: 'payments2:export:payments2',
        METHODS: 'payments2:methods:payments2',
        READ: 'payments2:read:payments2',
        TRANSACTIONS_READ: 'transactions:read:payments2',
        USER: 'payments2:user:payments2',
        WRITE: 'payments2:write:payments2',
    },
    PAYMENT_PACKAGES: {
        AUTHZSERVICENAME: 'payment-packages',
        READ: 'payment-packages:read:payment-packages',
    },
    PSP_BALANCES: {
        AUTHZSERVICENAME: 'psp-balances',
        READ: 'psp-balances:read:psp-balances',
    },
    RECONCILIATION: {
        AUTHZSERVICENAME: 'reconciliation',
        READ: 'reconciliation:read:reconciliation',
        WRITE: 'reconciliation:write:reconciliation',
    },
    ROUTING: {
        READ: 'payment-provider-routing:read:payments2',
        WRITE: 'payment-provider-routing:write:payments2',
    },
    KYC: {
        AUTHZSERVICENAME: 'kyc',
        READ: 'docs:read:kyc',
        WRITE: 'docs:write:kyc',
        USER_READ: 'user:read:kyc',
        USER_WRITE: 'user:write:kyc',
    },
    DOCUMENT_REQUESTS: {
        READ: 'document-requests:read:kyc',
        CREATE: 'document-requests:create:kyc',
        DELETE: 'document-requests:delete:kyc',
    },
    BETS: {
        AUTHZSERVICENAME: 'bets',
        CANCEL: 'ticket:cancel:bets',
        FREEZE: 'ticket:freeze:bets',
        REVERT: 'ticket:revert:bets',
        UNFREEZE: 'ticket:unfreeze:bets',
        VIEW_HISTORY: 'ticket:viewHistory:bets',
        READ: 'bets:read:bets',
        WRITE: 'bets:write:bets',
        W2G_RESOLVE: 'ticket:w2GResolve:bets',
    },
    CLIENTS: {
        AUTHZSERVICENAME: 'client-sender',
        CLIENTS_WRITE: 'clients:write:client-sender',
        CLIENTS_READ: 'clients:read:client-sender',
    },
    RECOMMENDATIONS: {
        AUTHZSERVICENAME: 'recommendations',
        RECOMMENDATIONS_WRITE: 'recommendations:write:recommendations',
    },
    EMAIL: {
        AUTHZSERVICENAME: 'email',
        EMAIL_READ: 'email:read:email',
        EMAIL_WRITE: 'email:write:email',
        EMAIL_ADMIN: 'email:admin:email',
        EMAIL_SEND: 'email:send:email',
    },
    SBGATE: {
        AUTHZSERVICENAME: 'sbgate',
        MAINTENANCE: 'maintenance:write:sbgate',
    },
    SPORTS: {
        AUTHZSERVICENAME: 'sports',
        SMART_RESULTING_CONFIGURATION: 'smartResultingConfiguration:write:sports',
        SPORTS: 'sports:write:sports',
        TEASERS_CONFIGURATION: 'teasersConfiguration:write:sports',
        PARLAY_CARD_CONFIGURATION: 'parlayCardConfiguration:write:sports',
        CATEGORY_FORMULA_SET: 'categoryFormulaSet:write:sports',
        OVERRIDE_CORE_RESULTING: 'matchSettings_OverrideCoreResulting:write:sports',
        LIMITS_CONFIGURATION: 'limitsConfiguration:write:sports',
        FEED_USERS: 'feedUsers:write:sports',
        UPDATE_LEAGUE_REGION: 'updateLeagueRegion:sports',
        FORCE_RESETTLE_OUTCOMES: 'forceResettleOutcome:write:sports',
        COMBO_CARD_HISTORY: 'comboCardHistory:read:sports',
        COMBO_CARD: 'comboCard:sports',
    },
    SPORTSBOOK_RISK: {
        AUTHZSERVICENAME: 'sportsbook-risk',
        READ: 'sportsbook-risk:read:sportsbook-risk',
    },
    TICKET_LIST: {
        AUTHZSERVICENAME: 'ticket-list',
        READ: 'ticket-list:read:ticket-list',
    },
    CMS: {
        AUTHZSERVICENAME: 'cms',
        CMS_WRITE: 'cms:write:cms',
    },
    USERS_PROXY: {
        AUTHZSERVICENAME: 'users-proxy',
        USERS_READ: 'users:read:users-proxy',
    },
    DIGITAL_BOARD: {
        AUTHZSERVICENAME: 'digital-board',
        DIGITAL_BOARD: 'digital-board:access:digital-board',
        feature: 'retail',
    },
    FEATURES: {
        AUTHZSERVICENAME: 'features',
        READ: 'features:read:features',
        EDIT: 'features:edit:features',
    },
    RETAIL_PROXY: {
        AUTHZSERVICENAME: 'retail-proxy',
        BO_INQUIRY: 'bo-inquiry:access:retail-proxy',
        ADMIN: 'admin:access:retail-proxy',
        CASHBOX: 'cashbox:access:retail-proxy',
        CMS: 'cms:access:retail-proxy',
        CONFIGURATIONS: 'configurations:access:retail-proxy',
        INQUIRY: 'inquiry:access:retail-proxy',
        ISSUE: 'issue:access:retail-proxy',
        ISSUE_VOUCHER: 'issue-voucher:access:retail-proxy',
        LOYALTY: 'loyalty:access:retail-proxy',
        MANAGEMENT: 'management:access:retail-proxy',
        OBSERVE: 'observe:access:retail-proxy',
        OBSERVER: 'observer:access:retail-proxy',
        REDEEM: 'redeem:access:retail-proxy',
        REPORTS: 'reports:access:retail-proxy',
        SETTINGS: 'settings:access:retail-proxy',
        SHIFT_PRINT_REPORT: 'shift-print-report:access:retail-proxy',
        SHIFT_SUMMARY_FULL: 'shift-summary-full:access:retail-proxy',
        SHIFT_SUMMARY: 'shift-summary:access:retail-proxy',
        SHIFT: 'shift:access:retail-proxy',
        SHOP_SETTINGS: 'shop-settings:access:retail-proxy',
        VOID: 'void:access:retail-proxy',
        ONGOING_SHIFTS: 'ongoing-shifts:access:retail-proxy',
        feature: 'retail',
        WEB_TRANSFERS: 'web-transfers:access:retail-proxy',
    },
    SECURITY_MATCH: {
        AUTHZSERVICENAME: 'security-match',
        ALL: 'security-match:all:security-match',
        READ: 'security-match:read:security-match',
    },
    SEGMENTS: {
        AUTHZSERVICENAME: 'segments',
        SEGMENTS_WRITE: 'segments:write:segments',
        SEGMENTS_READ: 'segments:read:segments',
        SEGMENTS_CSV_EXPORT: 'segments:csv-export:segments',
    },
    CASINO: {
        AUTHZSERVICENAME: 'casino',
        GAMES_WRITE: 'games:write:casino',
        GAMES_READ: 'games:read:casino',
    },
    'CASINO-SEGMENTS': {
        AUTHZSERVICENAME: 'casino-segments',
        CASINO_SEGMENTS_READ: 'casino-segments:read:casino-segments',
        CASINO_SEGMENTS_WRITE: 'casino-segments:write:casino-segments',
    },
    HIGHLIGHT_GAMES: {
        AUTHZSERVICENAME: 'highlight-games',
        MAINTENANCE_WRITE: 'highlight-games:write:highlight-games',
        MAINTENANCE_READ: 'highlight-games:read:highlight-games',
    },
    PROFANITIES: {
        AUTHZSERVICENAME: 'profanity',
        PROFANITIES_WRITE: 'profanities:write:profanity',
        PROFANITIES_READ: 'profanities:read:profanity',
        feature: 'profanity',
    },
    CASINO_GAME_ROUNDS: {
        AUTHZSERVICENAME: 'casino-game-rounds',
        READ: 'game-rounds:read:casino-game-rounds',
    },
    CASINO_BONUSES: {
        AUTHZSERVICENAME: 'casino-bonuses',
        BONUSES_WRITE: 'free-spin-bonus:write:casino-bonuses',
        BONUSES_READ: 'free-spin-bonus:read:casino-bonuses',
    },
    CASINO_RACE: {
        AUTHZSERVICENAME: 'casino-race',
        READ: 'casino-race:read:casino-race',
    },
    REFER_A_FRIEND: {
        AUTHZSERVICENAME: 'refer-a-friend',
        VIEW: 'refer-a-friend:view:refer-a-friend',
    },
    POKER_PLAYTECH: {
        AUTHZSERVICENAME: 'poker-playtech',
        READ: 'poker:read:poker-playtech',
        WRITE: 'poker:write:poker-playtech',
    },
    POKER_PLAYTECH_GAMESLINK: {
        AUTHZSERVICENAME: 'poker-playtech-gameslink',
        READ: 'poker-playtech-gameslink:read:poker-playtech-gameslink',
    },
    COOLBET_OPEN: {
        AUTHZSERVICENAME: 'coolbet-open',
        READ: 'schedule:read:coolbet-open',
        WRITE: 'schedule:write:coolbet-open',
    },
    PAYBACK: {
        AUTHZSERVICENAME: 'payback',
        PAYBACK_WRITE: 'payback:write:payback',
        PAYBACK_READ: 'payback:read:payback',
        feature: 'payback',
    },
    WALLET: {
        AUTHZSERVICENAME: 'wallet',
        MANUAL: 'wallet:manual',
        MANUAL_CORRECTIONS_OVERRIDE_AMOUNT_LIMIT: 'manual-corrections:override-amount-limit:wallet',
        VIRTUAL_CURRENCIES_READ: 'virtual-currencies:read:wallet',
    },
    WALLET_PROXY: {
        AUTHZSERVICENAME: 'wallet-proxy',
        READ: 'wallet-proxy:read:wallet-proxy',
    },
    BONUSES: {
        AUTHZSERVICENAME: 'bonuses',
        USERBONUS_CANCEL: 'userBonus:cancel:bonuses',
        READ: 'bonus:read:bonuses',
    },
    AUTH: {
        AUTHZSERVICENAME: 'auth',
        REQUEST_RESET_PASSWORD: 'password:request-reset:auth',
        READ: 'auth-data:read:auth',
        WRITE: 'auth-data:write:auth',
    },
    LIBERATION: { AUTHZSERVICENAME: 'liberation', GET: 'userdata:get:liberation' },
    RESPONSIBLE_GAMING: {
        AUTHZSERVICENAME: 'responsible-gaming',
        READ: 'responsible-gaming:read:responsible-gaming',
        WRITE: 'responsible-gaming:write:responsible-gaming',
        INDEFINITE_EXCLUSION: 'self-exclusion:indefinite-exclusion:responsible-gaming',
    },
    MATCH_OF_THE_DAY: {
        AUTHZSERVICENAME: 'match-of-the-day',
        MOTD_WRITE: 'match-of-the-day:write:match-of-the-day',
        MOTD_READ: 'match-of-the-day:read:match-of-the-day',
    },
    REPORTING: {
        AUTHZSERVICENAME: 'reporting',
        VIEW: 'reporting-tasks:view:reporting',
        WRITE: 'reporting-tasks:write:reporting',
    },
    OPENBET: {
        AUTHZSERVICENAME: 'openbet',
        READ: 'openbet:read:openbet',
        WRITE: 'openbet:write:openbet',
    },
    INTEGRITY: { AUTHZSERVICENAME: 'integrity', READ: 'integrity:read:integrity', WRITE: 'integrity:write:integrity' },
    SMS: {
        AUTHZSERVICENAME: 'sms2',
        SMS_ADMIN: 'sms2:admin:sms2',
        SMS_READ: 'sms2:read:sms2',
        SMS_WRITE: 'sms2:write:sms2',
    },
};

let keycloak;

export interface Credentials {
    username: string;
    password: string;
}

export interface Tokens {
    token: string;
    refreshToken: string;
    idToken: string;
}

interface KeycloakTokenResponse {
    access_token: string;
    expires_in: number;
    refresh_expires_in: number;
    refresh_token: string;
    token_type: string;
    id_token: string;
    'not-before-policy': number;
    session_state: string;
    scope: string;
}

export async function requestKeycloakToken({ username, password }: Credentials): Promise<KeycloakTokenResponse> {
    const { KEYCLOAK_AUTH_URL, KEYCLOAK_AUTH_REALM } = getStoreValue(store.environment);
    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('client_id', 'backoffice');
    urlencoded.append('username', username);
    urlencoded.append('password', password);
    urlencoded.append('grant_type', 'password');
    urlencoded.append('scope', 'openid');

    const response = await fetch(`${KEYCLOAK_AUTH_URL}/realms/${KEYCLOAK_AUTH_REALM}/protocol/openid-connect/token`, {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow',
    });
    if (response.status < 200 || response.status >= 300) {
        throw new Error(`Request to ${response.url} failed with ${response.status}`);
    }
    return response.json();
}

export function initKeycloak() {
    const { KEYCLOAK_AUTH_URL, KEYCLOAK_AUTH_REALM } = getStoreValue(store.environment);
    keycloak = new Keycloak({
        clientId: 'backoffice',
        realm: KEYCLOAK_AUTH_REALM,
        url: KEYCLOAK_AUTH_URL,
        'ssl-required': 'external',
        resource: 'backoffice',
        'public-client': true,
        'confidential-port': 0,
    } as any);
}

export function useKeycloak() {
    async function updateKeycloakAuthToken() {
        log('updateKeycloakAuthToken');
        await keycloak.updateToken(-1);
        store.tokenAuth.set(keycloak.token);
    }

    async function updateTokens() {
        try {
            await updateKeycloakAuthToken();
            await updateCoolbetAuthToken();
            socketAuthenticate();
            store.isAuthenticated.set(true);
        } catch (error) {
            logger.error('KeycloakService', 'updateTokens', error);
            logout();
        }
    }

    function hasRole(permissionString) {
        const authzPermissions: any = getStoreValue(store.authzPermissions);
        log(authzPermissions);
        const [resource, permission, service] = permissionString.split(':');

        if (!permission) {
            return keycloak.hasResourceRole(resource);
        }

        if (service) {
            return authzPermissions.includes(permissionString);
        }

        if (resource === 'realm') {
            return keycloak.hasRealmRole(permission);
        }

        return keycloak.hasResourceRole(permission, resource);
    }

    function hasAccessForPermissions(permissions?: string[]) {
        const authzPermissions = getStoreValue(store.authzPermissions);
        return permissions?.length ? difference(permissions, authzPermissions).length === 0 : true;
    }

    async function getAuthorizationsPermissions() {
        const permissionResourceServices = Object.keys(ROLES).reduce((accumulator, role) => {
            if (ROLES[role]) {
                const isAvailable = ROLES[role].feature ? isFeatureAvailable(ROLES[role].feature) : true;
                if (ROLES[role].AUTHZSERVICENAME && isAvailable) {
                    accumulator.push(ROLES[role].AUTHZSERVICENAME);
                }
            }

            return accumulator;
        }, [] as any);

        const permissions = await httpPost(
            getServiceUrl('auth-bo', 'user/permissions'),
            {
                audiences: permissionResourceServices,
                client_id: KEYCLOAK_CLIENT_ID,
                grant_type: KEYCLOAK_GRANT_TYPE,
            },
            {
                headers: {
                    Authorization: `Bearer ${keycloak.token}`,
                },
            },
        );

        return permissions;
    }

    return {
        async init(tokens?: Tokens, preventLocalTokensUpdate = false) {
            keycloak.onAuthSuccess = () => {
                if (!preventLocalTokensUpdate) {
                    setKeycloakTokensToLocalStorage();
                }
            };

            keycloak.onAuthError = error => {
                const errorString = `#Keycloak-onAuthError-${JSON.stringify(error)}`;
                logger.error('KeycloakService', 'onAuthError', errorString);
            };

            keycloak.onTokenExpired = async () => {
                log('access token has expired');
                await updateTokens();
            };

            keycloak.onAuthRefreshSuccess = () => {
                if (!preventLocalTokensUpdate) {
                    setKeycloakTokensToLocalStorage();
                }
                log('tokens have been refreshed and set to local storage');
            };

            keycloak.onAuthRefreshError = () => {
                logger.error(
                    'KeycloakService',
                    'onAuthRefreshError',
                    '#Keycloak-onAuthRefreshError - Probably the session expired.',
                );
                logout();
            };

            if (tokens) {
                await initKeycloakAuth({
                    onLoad: 'check-sso',
                    checkLoginIframe: false,
                    ...tokens,
                });
            } else {
                await initKeycloakAuth({ onLoad: 'login-required' });
            }

            const authzPermissions = await getAuthorizationsPermissions();
            store.authzPermissions.set(authzPermissions);

            const boUser = await exchangeKeycloakTokenForCoolbetToken();
            await updateTokens();

            return boUser;
        },
        updateTokens,
        updateToken: updateKeycloakAuthToken,
        hasRole,
        hasAccessForPermissions,
    };
}

function safelyParseJSON(json) {
    try {
        return JSON.parse(json);
    } catch (error) {
        logger.error('KeycloakService', 'safelyParseJSON', error);
        return undefined;
    }
}

export function initLocalStorageListenerForKeycloakTokens() {
    window.addEventListener('storage', async event => {
        if (event.key === STORAGE_KEY_KEYCLOAK_TOKENS) {
            log('(initLocalStorageListenerForKeycloakTokens) Tokens have been updated!');

            let tokens = safelyParseJSON(event.newValue);

            let retries = 5;
            while (!tokens && retries > 0) {
                retries--;
                await wait(100); // wait for tokens to be set to local storage
                tokens = safelyParseJSON(localStorage.getItem(STORAGE_KEY_KEYCLOAK_TOKENS));
            }

            if (!tokens) {
                logger.log('KeycloakService', 'initLocalStorageListenerForKeycloakTokens', 'No tokens found');
                logoutFromKeycloak();
            } else {
                setKeycloakToken(tokens);
                useKeycloak().init(keycloak as Tokens, true);
            }
        }
    });
}

export function emitKeycloakTokens(tokens?: any) {
    window.dispatchEvent(
        new StorageEvent('storage', {
            key: STORAGE_KEY_KEYCLOAK_TOKENS,
            newValue: JSON.stringify(tokens),
        }),
    );
}

async function wait(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export function logoutFromKeycloak() {
    window.localStorage.removeItem(STORAGE_KEY_KEYCLOAK_TOKENS);
    if (keycloak) {
        resetKeycloakState();
        keycloak.logout();
    }
}

async function initKeycloakAuth(initOptions: KeycloakInitOptions) {
    if (isTokenValid(keycloak.refreshToken)) {
        log('(initKeycloakAuth) Setting existing tokens to initOptions!');
        Object.assign(initOptions, keycloak);
    }

    const isAuthenticated = await keycloak.init(initOptions);

    if (isAuthenticated) {
        await keycloak.updateToken(0);
    } else {
        log('(initKeycloakAuth) initAuthentication failed. Forcing login!');
        await keycloak.login();
    }
}

function setKeycloakTokensToLocalStorage() {
    window.localStorage.setItem(
        STORAGE_KEY_KEYCLOAK_TOKENS,
        JSON.stringify({
            token: keycloak.token,
            refreshToken: keycloak.refreshToken,
            idToken: keycloak.idToken,
        }),
    );
}

export interface KeycloakUser {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    displayName: string;
}

async function exchangeKeycloakTokenForCoolbetToken() {
    log('[keycloak] exchange token for cb token');
    const {
        id,
        token,
        email,
        first_name: firstName,
        last_name: lastName,
    } = await httpPost('/s/auth-bo/auth/token/exchange', undefined, {
        headers: {
            Authorization: `Bearer ${keycloak.token}`,
        },
    });
    const boUser = {
        id: id,
        email: email,
        firstName,
        lastName,
        displayName: `${firstName} ${lastName}`,
    };
    store.tokenCbAuth.set(token);
    store.boUser.set(boUser);
    Sentry.setUser({
        id,
        email,
        username: `${firstName} ${lastName}`,
    });
    return boUser;
}

function setKeycloakToken({
    token,
    refreshToken,
    idToken,
    timeLocal,
}: {
    token: string;
    refreshToken: string;
    idToken: string;
    timeLocal?: any;
}) {
    // This is copied (with naming modifications) from keycloak-js/dist/keycloak.js>setToken
    if (keycloak.tokenTimeoutHandle) {
        clearTimeout(keycloak.tokenTimeoutHandle);
        keycloak.tokenTimeoutHandle = null;
    }

    try {
        if (refreshToken) {
            keycloak.refreshToken = refreshToken;
            keycloak.refreshTokenParsed = decode(refreshToken);
        } else {
            delete keycloak.refreshToken;
            delete keycloak.refreshTokenParsed;
        }
        if (idToken) {
            keycloak.idToken = idToken;
            keycloak.idTokenParsed = decode(idToken);
        } else {
            delete keycloak.idToken;
            delete keycloak.idTokenParsed;
        }
        if (token) {
            keycloak.token = token;
            keycloak.tokenParsed = decode(token);
        } else {
            delete keycloak.token;
            delete keycloak.tokenParsed;
        }
    } catch (error) {
        logger.error('KeycloakService', 'setKeycloakToken', error);
        logout();
    }

    if (keycloak.tokenParsed) {
        keycloak.sessionId = keycloak.tokenParsed.session_state;
        keycloak.authenticated = true;
        keycloak.subject = keycloak.tokenParsed.sub;
        keycloak.realmAccess = keycloak.tokenParsed.realm_access;
        keycloak.resourceAccess = keycloak.tokenParsed.resource_access;

        if (timeLocal) {
            keycloak.timeSkew = Math.floor(timeLocal / 1000) - keycloak.tokenParsed.iat;
        }

        if (keycloak.timeSkew != null) {
            log(`Estimated time difference between browser and server is ${keycloak.timeSkew} seconds`);

            if (keycloak.onTokenExpired) {
                const expiresIn =
                    (keycloak.tokenParsed['exp'] - new Date().getTime() / 1000 + keycloak.timeSkew) * 1000;
                log(`Token expires in ${Math.round(expiresIn / 1000)} s`);

                if (expiresIn <= 0) {
                    keycloak.onTokenExpired();
                } else {
                    keycloak.tokenTimeoutHandle = setTimeout(keycloak.onTokenExpired, expiresIn);
                }
            }
        }
    } else {
        resetKeycloakState();
    }
}

export function sessionData() {
    if (!keycloak) {
        return {};
    }
    return { sessionId: keycloak.sessionId, userId: keycloak.subject };
}

function resetKeycloakState() {
    delete keycloak.token;
    delete keycloak.tokenParsed;
    delete keycloak.subject;
    delete keycloak.realmAccess;
    delete keycloak.resourceAccess;

    keycloak.authenticated = false;
}

function log(...rest) {
    // remove commented out code if needed again
    // console.info(dayjs().format(), '[KEYCLOAK]', ...rest);
}
